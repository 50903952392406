* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Sanuk';
  src: url('./assets/fonts/Sanuk-Regular.woff2') format('woff2'),
    url('./assets/fonts/Sanuk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SanukLF';
  src: url('./assets/fonts/SanukLF-Medium.woff2') format('woff2'),
    url('./assets/fonts/SanukLF-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SanukTF';
  src: url('./assets/fonts/SanukTF-Bold.woff2') format('woff2'),
    url('./assets/fonts/SanukTF-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sanuk-MediumSC';
  src: url('./assets/fonts/Sanuk-MediumSC.woff2') format('woff2'),
    url('./assets/fonts/Sanuk-MediumSC.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Sanuk', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
#root > div {
  height: 100%;
  width: 100%;
}

canvas {
  height: 100%;
}

svg {
  height: 100vh;
}

main {
  width: 80%;
  margin: 0 auto;
  padding: 30px;
}

.backIcon {
  position: absolute;
  font-size: 60px;
  top: 30px;
  left: 30px;
  color: white;
}

@media screen and (max-width: 8000px) {
  main {
    width: 100%;
  }

  .backIcon {
    position: absolute;
    font-size: 60px;
    top: 30px;
    left: auto;
    right: 30px;
    color: white;
  }
}

a {
  color: #458f8e;
  text-decoration: none;
}

.link {
  color: white !important;
}

a:hover,
.link:hover {
  text-decoration: underline;
}

p {
  line-height: 2;
}

.place-info,
.global-info {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  color: white;
  z-index: 2;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
}

.global-info {
  right: auto !important;
  left: 0;
  min-width: 250px;
  display: flex;
  justify-content: space-around;
}

.global-info.mobile {
  width: 100%;
  top: auto;
  bottom: 0;
  height: 50px;
}

.place-info.mobile {
  width: 100%;
  font-size: 14px;
  height: 50px;
}

.place-info > div,
.global-info > div {
  margin: 10px 0;
}

.plusIcon {
  width: 35px;
  height: 35px;
  background-image: url('./assets/plus.svg');
  background-size: contain;
}

.plusIcon:hover {
  background-image: url('./assets/plus_selected.svg');
}

.infoIcon {
  width: 35px;
  height: 35px;
  background-image: url('./assets/info.svg');
  background-size: contain;
  margin-top: 10px;
}

.grabIcon {
  width: 30px;
  height: 30px;
  background-image: url('./assets/hand.svg');
  background-size: contain;
  margin-top: 10px;
}

.mapIcon {
  width: 25px;
  height: 25px;
  background-image: url('./assets/marker.svg');
  background-size: contain;
  margin-top: 10px;
  background-repeat: no-repeat;
}

.infoIcon:hover {
  background-image: url('./assets/info_selected.svg');
}

.homeTitle {
  background-color: #458f8e;
  width: 100%;
  min-width: 200px;
  min-height: 18vw;
  font-size: 2.5vw;
  color: white;
  padding: 10px;
  font-weight: bold;
  max-width: 350px;
  font-family: 'SanukTF';
}

.homeSubTitle {
  margin-top: 25px;
  font-size: 1.2vw;
  color: white;
}

.homeSubTitle2 {
  margin-top: 15px;
  font-size: 1.7vw;
  color: white;
}

.landingTitle {
  width: 100%;
  font-size: 70px;
  color: white;
  padding: 10px;
  font-weight: bold;
  font-family: 'SanukTF';
  text-align: left;
}

.landingSubTitle {
  font-size: 30px;
  color: white;
}

@media screen and (max-width: 1000px) {
  .homeTitle {
    font-size: 30px;
  }

  .homeSubTitle {
    margin-top: 15px;
    font-size: 16px;
  }

  .homeSubTitle2 {
    font-size: 20px;
  }

  .landingTitle {
    font-size: 40px;
  }

  .landingSubTitle {
    font-size: 24px;
  }
}

.landingMap {
  background-image: url('./assets/landing.svg');
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #579b8e;
  cursor: pointer;
}
